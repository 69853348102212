<template>
	<b-container fluid v-if="$checkPermission('view-landing-page')" id="landingPage">
		<cg-modal :data="newLandingPageFields" @update="addNewLandingPage"></cg-modal>

		<cg-loader :display="loading"></cg-loader>

		<cg-localized-alert :alertVariant="alertVariant" ref="cgLocAlert" :alertMessage="alertMessage"></cg-localized-alert>

		<div class="px-4 mb-4">
			<b-card-title class="mb-4">{{ $t('Navbar.LandingPage') }}</b-card-title>

			<!-- Landing Page Options -->
			<b-row align-h="end" class="mb-4">
				<b-col md="12" lg="8" align-self="end">
					
					<!-- New Landing Page button -->
					<b-row align-h="end">
						<b-col cols="auto">
							<b-button v-if="$checkPermission('create-landing-page')" @click="createNewLandingPage()" size="sm" variant="primary" class="mb-3">
								<font-awesome-icon class="vertical-align-middle mr-2" :icon="['fas', 'plus']"/>{{ $t('LandingPage.NewLandingPage') }}
							</b-button>
						</b-col>
					</b-row>

					<!-- Landing Page Search -->
					<b-form-group :label="$t('General.Search')" label-cols-sm="3" label-cols-md="4" label-cols-xl="5" label-align-sm="right" label-size="sm" label-for="searchLPInput">
						<b-input-group size="sm">
							<b-form-input v-model="filter" @input="searchLandingPages()" type="search" debounce="500" id="searchLPInput" 
								:placeholder="$t('General.TypeToSearch')"></b-form-input>
							<template v-slot:append>
								<b-input-group-text class="icon-append">
									<font-awesome-icon class="vertical-align-middle" :icon="['fas', 'search']"/>
								</b-input-group-text>
							</template>
						</b-input-group>
					</b-form-group>
					
					<!-- Landing Page Filter options -->
					<b-form-group :label="$t('General.Show')" label-cols-sm="3" label-cols-md="4" label-cols-xl="5" label-align-sm="right" label-size="sm" class="lpScopeSelect"
							label-for="lpScopeFilter">
						<b-form-select class="mr-2" id="lpScopeFilter" size="sm" v-model="lpScopeFilter" :options="lpScopeOptions"></b-form-select>
					</b-form-group>
						
				</b-col>
			</b-row>
		
			<!-- Landing Page Cards -->
			<div class="mt-3 w-100">
				<b-card-group deck v-if="lPages.length > 0">
					<cg-lp-card
						v-for="(lp, index) in filteredLandingPages"
						:landingPage="lp"
						:searchText="filter"
						:companyData="companyData"
						:index="index"
						:key="lp.page_id"
						:previewHints="previewHints"
						:ref="lp.page_id"
						v-on:landing_page_deleted="reload($event)"
						v-on:landing_page_reload="getLandingPages()"
						@landing_page_copied="copied"
					></cg-lp-card>
				</b-card-group>
				<div v-if="lPages.length == 0 && !loading" class="text-center line-height-200">{{ $t('LandingPage.NoLandingPages') }}</div>
			</div>
		</div>

	</b-container>
</template>

<script>
import landingpageService from '../services/landingpage.service';
import syspropService from "../services/sysprop.service";
import companyService from "../services/company.service";
import { LandingPageHtml } from '../../../server/common/constants';
import { LandingPageScope } from '../../../server/common/constants';
import Vue from 'vue';

export default {
	data: function() {
		return {
			filter: '',
			lPages: [],
			loading: false,
			searchTimeout: null,
			landingPageImport: null,
			previewHints: null,
			companyData: null,
			companyId: localStorage.getItem('cg-company'),
			alertMessage: '',
			alertVariant: 'success',
			newLandingPageFields: {
				name: 'new-landing-page',
				title: 'LandingPage.NewLandingPage',
				label: 'LandingPage.NewLandingPage',
				item: { language: this.$i18n.locale },
				submitHandler: "addNewLandingPage",
				fields:[
					{   id: "name",
						label: 'LandingPage.Name',
						type: "text",
						required: true,
						display: true,
						placeholder: 'LandingPage.Name',
					},
					{   id: "language",
						label: 'LandingPage.Language',
						type: "select",
						display: true,
						placeholder: 'LandingPage.Language',
					},
					{   id: "companyId",
						label: 'LandingPage.Company',
						type: "select",
						display: true,
						options: []
					}]
			},
			lpScopeFilter: null,
			lpScopeOptions: []
		};
	},
	computed: {
		filteredLandingPages() {
			let tmp = this.lPages;

			if (this.lpScopeFilter != LandingPageScope.GlobalAndCompany) {
				tmp = tmp.filter((lp) => { return lp.scope == this.lpScopeFilter });
			}

			return tmp;
		}
	},
	methods: {
		reload(event) {
			console.log("LandingPage - reload", event);
			this.getLandingPages();
			this.alertMessage = 'LandingPage.DeleteSuccess';
			this.alertVariant = 'success';
			this.$refs.cgLocAlert.showAlert();
		},
		async getLandingPages() {
			this.loading = true;

			try {
				let res = await landingpageService.getLandingPages(this.companyId, LandingPageScope.GlobalAndCompany, this.filter);
				console.debug("LandingPage - getLandingPages success", res);
				this.lPages = res.data;
			} catch (error) { 
				console.error("LandingPage - getLandingPages error", error);
			}
			this.loading = false;
		},
		createNewLandingPage() {
			this.cleanForm();
			this.$bvModal.show(this.newLandingPageFields.name);
		},
		cleanForm() {
            this.newLandingPageFields.item = {
				name: null,
				language: this.$i18n.locale,
				companyId: this.defaultCompanyId
			}
        },
		async addNewLandingPage(newLandingPage) {
			this.loading = true;

			let landingPageLanguage = newLandingPage.language;
			let landingPageTitle = newLandingPage.name;
			let landingPageBody = this.$i18n.t('LandingPage.NewLandingPage');
			this.landingPageImport = LandingPageHtml.replace(/{{.landingPageLanguage}}/gim, landingPageLanguage)
				.replace(/{{.landingPageTitle}}/gim, landingPageTitle)
				.replace(/{{.landingPageBody}}/gim, landingPageBody);

			try {
				let res = await landingpageService.createLandingPage(newLandingPage.companyId, this.landingPageImport, landingPageTitle, landingPageLanguage);
				this.newLandingPage = res.data;
				console.debug("LandingPage - createLandingPage success", res);
				this.alertVariant = 'success';
				this.alertMessage = 'LandingPage.CreateSuccess';
				this.getLandingPages();
			} catch (error) { 
				console.error("LandingPage - createLandingPage error", error);
				this.alertVariant = 'danger';
				this.alertMessage = 'LandingPage.CreatedError';
			} finally {
				this.loading = false;
				this.$refs.cgLocAlert.showAlert();
				this.landingPageImport = null;
			}

			if(newLandingPage) {
				setTimeout(() => {
					let justCreated = this.lPages.filter(lp => lp.page_id == this.newLandingPage.page);
					if(justCreated && justCreated.length) {
						this.$refs[justCreated[0].page_id][0].showPreview();
					}
				}, 500)
			}
		},
		searchLandingPages() {
			if (this.searchTimeout) {
				clearTimeout(this.searchTimeout);
			}
			this.searchTimeout = setTimeout(this.getLandingPages, 700);
		},
		copied(alertMessage, alertVariant) {
			this.alertVariant = alertVariant;
			this.alertMessage = alertMessage;

			this.$refs.cgLocAlert.showAlert();
			this.getLandingPages();
		}
	},
	async created() {
		const config = await syspropService.getConfig();
		this.companyData = { contact: config.data.defaults.contact, gp_landing_url: config.data.defaults.gp_landing_url };

		if(this.companyId) {
			let companyRes = await companyService.getCompany(this.companyId);
			this.companyData.company = companyRes.data;
		}
		
		this.lpScopeOptions = [
			{ value: LandingPageScope.GlobalAndCompany, text: this.$t('LandingPage.GlobalAndCompany') },
			{ value: LandingPageScope.Global, text: this.$t('LandingPage.Global') },
			{ value: LandingPageScope.Company, text: this.$t('LandingPage.Company') }
		];

		if (this.$checkPermission('list-company-all')) {
			this.lpScopeFilter = LandingPageScope.GlobalAndCompany; 
		} else { // Limit the scope to company
			this.lpScopeFilter = LandingPageScope.Company;
		}

		this.getLandingPages();

		if(this.$checkPermission('view-hint')) {
			const hints = await landingpageService.getPreviewHints();
			this.previewHints = hints.data;
		}

		this.defaultCompanyId = this.companyId;

		let companyOptions = [];

		// Check if the logged user is admin
		if (this.$checkPermission('list-company-all')) {
			this.defaultCompanyId = null;
			companyOptions.push( { value: null, text: this.$t('LandingPage.Global') } );	
		}

		companyOptions.push({ value: this.companyId, text: this.companyData.company.name });
		Vue.set(this.newLandingPageFields.fields.find(i => i.id === 'companyId'), 'options', companyOptions);
		Vue.set(this.newLandingPageFields.fields.find(i => i.id === 'language'), 'options', this.$langs);
	}
};
</script>
 
<style lang="less">
#landingPage {
	.card-title {
		font-size: 1.3em;
	}
	.card-deck .card {
		cursor: pointer;
		flex: 1 0 50%;
		max-width: calc((100% / 2) - 30px);
		&:hover {
			box-shadow: 0px 0px 7px #dedede;
		}
	}
}

// Medium devices (tablets, 992px and up)
@media only screen and (max-width: 992px) {
	#landingPage {
		.lpScopeSelect {
			margin-top: .5rem !important;
		}
	}
}
</style>